export default {
  data: () => ({
    propsPrimaryWindowToolbar: {
      dark: true,
      color: "primary-window",
    },

    propsSecondaryWindowToolbar: {
      dark: true,
      color: "secondary-window",
    },
    propsSecondaryWindowTab: {
      dark: true,
      "background-color": "secondary-window",
      "slider-color": "yellow",
    },

    propsTertiaryWindowToolbar: {
      dark: true,
      color: "tertiary-window",
    },
    propsTertiaryWindowTab: {
      dark: true,
      "background-color": "tertiary-window",
      "slider-color": "cyan",
    },

    propsCardTile: {
      // color: "#FFF8E1",
      tile: true,
      flat: true,
    },

    propsFormFields: {
      // outlined: true
    },
    propsDatePicker: {
      color: "primary",
      "first-day-of-week": "1",
    },
    propsTimePicker: {
      color: "primary",
      format: "24hr",
    },

    propsButtonCancel: {
      // text: true
    },
    propsButtonAction: {
      // text: true,
      color: "primary",
    },
    propsButtonConfirm: {
      // text: true,
      color: "primary",
    },
    propsButtonTrash: {
      // text: true,
      color: "red",
    },
    propsButtonTrashIcon: {
      text: true,
      icon: true,
      color: "red",
    },
  }),
  computed: {
    dialogWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%"
        case "sm":
          return "66%"
        case "md":
          return "50%"
        case "lg":
          return "33%"
        case "xl":
          return "33%"
      }
      return "50%"
    },
  },
}
