import Vue from "vue"
import Vuetify from "vuetify/lib"
import ca from "vuetify/es5/locale/ca"

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#1976D2",
        secondary: "#424242",
        accent: "#448AFF",
        giSport: "#00468C",

        error: "#F44336",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#F57C00", // Changed from #FFC107 to a darker amber for better contrast with white

        "main-background": "#EEEEEE",
        "primary-window": "#3F51B5",
        "secondary-window": "#00BCD4",
        "tertiary-window": "#BDBDBD",
      },
    },
  },
  icons: {
    iconfont: "mdi",
    values: {
      // Standard vuetify base icons
      // complete: "...",
      // cancel: "...",
      // close: "...",
      // delete: "...", // delete (e.g. v-chip close)
      // clear: "...",
      // success: "...",
      // info: "...",
      // warning: "...",
      // error: "...",
      // prev: "...",
      // next: "...",
      // checkboxOn: "...",
      // checkboxOff: "...",
      // checkboxIndeterminate: "...",
      // delimiter: "...", // for carousel
      // sort: "...",
      // expand: "...",
      // menu: "...",
      // subgroup: "...",
      // dropdown: "...",
      // radioOn: "...",
      // radioOff: "...",
      edit: "mdi-pencil",
      // ratingEmpty: "...",
      // ratingFull: "...",
      // ratingHalf: "...",
      // loading: "...",
      // first: "...",
      // last: "...",
      // unfold: "...",
      // file: "...",
      // Specific Tres65 icons
      area: "mdi-floor-plan",
      areas: "mdi-floor-plan",
      booking: "mdi-ticket-account",
      bookings: "mdi-ticket-account",
      calendar: "mdi-calendar-edit",
      member: "mdi-account",
      members: "mdi-account-multiple",
      match: "mdi-soccer",
      matches: "mdi-soccer",
      ticket: "mdi-receipt",
      tickets: "mdi-receipt",
      printer: "mdi-printer",
      printers: "mdi-printer",
      reports: "mdi-text-box-search-outline",
      invoicing: "mdi-bank-transfer",
      seat: "mdi-seat",
      seats: "mdi-seat",
      gate: "mdi-door",
      gates: "mdi-door",
      getIn: "mdi-login",
      messaging: "mdi-message-text-outline",

      new: "mdi-plus",
      list: "mdi-format-list-bulleted",
      refresh: "mdi-refresh",
      settings: "mdi-cog-box",
      login: "mdi-login",
      trash: "mdi-delete",
      date: "mdi-calendar",
      time: "mdi-calendar-clock",
      timeDone: "mdi-clock-check-outline",
      name: "mdi-account",
      surname: "mdi-account",
      idNumber: "mdi-card-account-details-outline",
      email: "mdi-at",
      birthday: "mdi-cake-variant",
      phone: "mdi-cellphone",
    },
  },
  lang: {
    locales: { ca },
    current: "ca",
  },
})
