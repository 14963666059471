/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap")

require("./helpers")

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import Vue from "vue"
import Login from "./Login.vue"
import i18n from "./i18n"
import vuetify from "./plugins/vuetify"
import vuetifyComponentProps from "./plugins/vuetifyComponentProps.mixin"

Vue.mixin(vuetifyComponentProps)

new Vue({
  i18n,
  vuetify,
  render: (h) => h(Login),
  el: "#app",
})

/*
Vue.component('example', require('./components/Example.vue'));

const app = new Vue({
    el: '#app'
});
*/
