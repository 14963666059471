<template>
  <v-app>
    <v-layout row align-center>
      <v-flex xs4 offset-xs4>
        <v-card light>
          <v-img class="white--text" :src="loginHeaderPath" height="300">
            <v-container fill-height fluid>
              <v-layout align-end>
                <v-flex xs12>
                  <span class="headline">{{ $t("login.text") }}</span>
                </v-flex>
              </v-layout>
            </v-container>
          </v-img>
          <v-card-text>
            <v-text-field
              name="name"
              :label="$t('login.username')"
              v-model="name"
              type="text"
              data-cy="input-login"
            ></v-text-field>
            <v-text-field
              name="password"
              :label="$t('login.password')"
              v-model="password"
              :append-icon="!clr_txt ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (clr_txt = !clr_txt)"
              :type="!clr_txt ? 'password' : 'text'"
              data-cy="input-password"
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="white">
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit" data-cy="button-login"
              >{{ $t("login.button") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-app>
</template>
<script>
export default {
  name: "app",
  components: {},
  data() {
    return {
      env: process.env,
      clr_txt: false,
      name: "",
      password: "",
    }
  },
  computed: {
    loginHeaderPath() {
      return `/img/${process.env.MIX_CLIENT_SLUG}/login-header.${process.env.MIX_CLIENT_ASSET_HASH}.jpg`
    },
  },
  created() {
    setTimeout(function () {
      window.location.reload(1)
    }, 30 * 60000)
  },
}
</script>
